const SPACE_DATA = {
		"u6982993":  [{space_js: "//hhaabb.hanganan.com/production/yts_b/v_i/production/bbl.js", space_src: "baidujs", space_type: "inters", space_id: "u6982993"}],
		"u6982994":  [{space_js: "//hhaabb.hanganan.com/common/avu/production/dx_k/static/ddr.js", space_src: "baidujs", space_type: "native", space_id: "u6982994"}],
		"u6982995":  [{space_js: "//hhaabb.hanganan.com/production/bw-v/openjs/e-y/static/leet.js", space_src: "baidujs", space_type: "native", space_id: "u6982995"}],
		"u6982996":  [{space_js: "//hhaabb.hanganan.com/production/cxw/f/zm/f/openjs/fw.js", space_src: "baidujs", space_type: "native", space_id: "u6982996"}],
		"u6982328":  [{space_js: "//hhaabb.hanganan.com/common/qlk_tna/static/da/common/n.js", space_src: "baidujs", space_type: "inters", space_id: "u6982328"}],
		"u6982329":  [{space_js: "//hhaabb.hanganan.com/common/rm/l-uob/static/eb/resource/u.js", space_src: "baidujs", space_type: "native", space_id: "u6982329"}],
		"u6982330":  [{space_js: "//hhaabb.hanganan.com/source/je/static/d-m/gtww/common/k.js", space_src: "baidujs", space_type: "native", space_id: "u6982330"}],
		"u6982331":  [{space_js: "//hhaabb.hanganan.com/source/kf/resource/e/source/n_hu/common/xxo.js", space_src: "baidujs", space_type: "native", space_id: "u6982331"}],
		"u6989616":  [{space_js: "//hhaabb.hanganan.com/site/x/srau/openjs/a/static/rb-r.js", space_src: "baidujs", space_type: "inters", space_id: "u6989616"}],
		"u6989617":  [{space_js: "//hhaabb.hanganan.com/source/y/t/source/s/openjs/b/resource/vbscu.js", space_src: "baidujs", space_type: "native", space_id: "u6989617"}],
		"u6989618":  [{space_js: "//hhaabb.hanganan.com/source/a/production/v/static/ud/xdu/common/ex.js", space_src: "baidujs", space_type: "native", space_id: "u6989618"}],
		"u6989619":  [{space_js: "//hhaabb.hanganan.com/source/bwve_ye/static/v/production/fe.js", space_src: "baidujs", space_type: "native", space_id: "u6989619"}],
		"u6981094":  [{space_js: "//hhaabb.hanganan.com/common/p/kjsmt_qs_g.js", space_src: "baidujs", space_type: "inters", space_id: "u6981094"}],
		"u6981095":  [{space_js: "//hhaabb.hanganan.com/common/qlk/static/t/n-u/common/rti.js", space_src: "baidujs", space_type: "native", space_id: "u6981095"}],
		"u6981096":  [{space_js: "//hhaabb.hanganan.com/source/rm/source/l_uovs/common/u/resource/l.js", space_src: "baidujs", space_type: "native", space_id: "u6981096"}],
		"u6981097":  [{space_js: "//hhaabb.hanganan.com/production/snm_v_p_wtv/production/o.js", space_src: "baidujs", space_type: "native", space_id: "u6981097"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6981094"],
	Home_banner:  SPACE_DATA["u6981095"],
	Home_native:  SPACE_DATA["u6981096"],
	Home_native_center1:  SPACE_DATA["u6981097"],
	Detail_inter:  SPACE_DATA["u6981094"],
	Detail_banner:  SPACE_DATA["u6981095"],
	Detail_native:  SPACE_DATA["u6981096"],
	Detail_native_center1:  SPACE_DATA["u6981097"],
	Result_inter:  SPACE_DATA["u6981094"],
	Result_banner:  SPACE_DATA["u6981095"],
	Result_native:  SPACE_DATA["u6981096"],
	Result_native_center1:  SPACE_DATA["u6981097"],
}
export const ALL_SPACE_MAP = {
    HNW_HAAI_VO_4 : {
		Home_inter:  SPACE_DATA["u6982993"],
		Home_banner:  SPACE_DATA["u6982994"],
		Home_native:  SPACE_DATA["u6982995"],
		Home_native_center1:  SPACE_DATA["u6982996"],
		Detail_inter:  SPACE_DATA["u6982993"],
		Detail_banner:  SPACE_DATA["u6982994"],
		Detail_native:  SPACE_DATA["u6982995"],
		Detail_native_center1:  SPACE_DATA["u6982996"],
		Result_inter:  SPACE_DATA["u6982993"],
		Result_banner:  SPACE_DATA["u6982994"],
		Result_native:  SPACE_DATA["u6982995"],
		Result_native_center1:  SPACE_DATA["u6982996"],
    },
    HNW_HAAI_VO_5 : {
		Home_inter:  SPACE_DATA["u6982328"],
		Home_banner:  SPACE_DATA["u6982329"],
		Home_native:  SPACE_DATA["u6982330"],
		Home_native_center1:  SPACE_DATA["u6982331"],
		Detail_inter:  SPACE_DATA["u6982328"],
		Detail_banner:  SPACE_DATA["u6982329"],
		Detail_native:  SPACE_DATA["u6982330"],
		Detail_native_center1:  SPACE_DATA["u6982331"],
		Result_inter:  SPACE_DATA["u6982328"],
		Result_banner:  SPACE_DATA["u6982329"],
		Result_native:  SPACE_DATA["u6982330"],
		Result_native_center1:  SPACE_DATA["u6982331"],
    },
    HNW_HAAI_VO_6 : {
		Home_inter:  SPACE_DATA["u6989616"],
		Home_banner:  SPACE_DATA["u6989617"],
		Home_native:  SPACE_DATA["u6989618"],
		Home_native_center1:  SPACE_DATA["u6989619"],
		Detail_inter:  SPACE_DATA["u6989616"],
		Detail_banner:  SPACE_DATA["u6989617"],
		Detail_native:  SPACE_DATA["u6989618"],
		Detail_native_center1:  SPACE_DATA["u6989619"],
		Result_inter:  SPACE_DATA["u6989616"],
		Result_banner:  SPACE_DATA["u6989617"],
		Result_native:  SPACE_DATA["u6989618"],
		Result_native_center1:  SPACE_DATA["u6989619"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6981094"],
		Home_banner:  SPACE_DATA["u6981095"],
		Home_native:  SPACE_DATA["u6981096"],
		Home_native_center1:  SPACE_DATA["u6981097"],
		Detail_inter:  SPACE_DATA["u6981094"],
		Detail_banner:  SPACE_DATA["u6981095"],
		Detail_native:  SPACE_DATA["u6981096"],
		Detail_native_center1:  SPACE_DATA["u6981097"],
		Result_inter:  SPACE_DATA["u6981094"],
		Result_banner:  SPACE_DATA["u6981095"],
		Result_native:  SPACE_DATA["u6981096"],
		Result_native_center1:  SPACE_DATA["u6981097"],
    },
};
