/** 根据url获取参数 */
export const getParamsFromUrl = (url: string): AnyObject => {
  let paramStr = url.split("?")[1];
  if (!paramStr) return {};
  if (paramStr.indexOf("#/") > -1) paramStr = paramStr.split("#/")[0];
  const kavs = paramStr.split("&");
  const result: AnyObject = {};
  kavs.forEach((item) => {
    const kv = item.split("=");
    result[kv[0]] = kv[1];
  });
  return result;
};

/** 获取随机数量字符串 */
export const randomString = (len: number) => {
  len = len || 32;
  let t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * a);
    const str = t.substr(index, 1);
    n += str;
  }
  return n;
};

/** 随机整数字符串 */
export const randomInt = function (e?: number) {
  e = e || 4;
  let t = "123456789",
    a = t.length,
    n = "";
  for (let i = 0; i < e; i++) {
    const index = Math.floor(Math.random() * a);
    const str = t.substr(index, 1);
    n += str;
  }
  return n;
};
